<table class="table table-sm table-hover table-striped">
  <thead>
  <tr>
    <th>Serial Number</th>
    <th>Brand</th>
    <th>Type</th>
    <th>Weight</th>
    <th>Material</th>
    <th>Fineness</th>
    <th>Enabled Facets</th>
    <th>Created</th>
    <th>Station</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let r of stockItems; index as i" #stockItemList
      [ngClass]="currentStockItem === r.stockItemId ? 'ae-select link-active' : 'link-active'"
      (click)="setStockItemEvent.emit({id: r.stockItemId, index: i})"
  >
    <td>
      <a class="link-active" matTooltip="click to open FeaturePrint in new tab"
         target="_blank"
         [routerLink]="['/featurePrint', this.dataset, r.stockItemId]"> {{ getSerialNumber(r.externalIdentifier) }} </a>
    </td>
    <td matTooltip="click row for details">{{ r.stockItemSetAnnotationData?.identifiers?.brand }}</td>
    <td matTooltip="click row for details">{{ r.stockItemSetAnnotationData?.identifiers?.type }}</td>
    <td matTooltip="click row for details">{{ r.stockItemSetAnnotationData?.identifiers?.weight }}</td>
    <td matTooltip="click row for details">{{ r.stockItemSetAnnotationData?.identifiers?.material }}</td>
    <td matTooltip="click row for details">{{ r.stockItemSetAnnotationData?.identifiers?.fineness }}</td>
    <td matTooltip="click row for details">{{ r.facetCount }}</td>
    <td matTooltip="click row for details">{{ r.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
    <td matTooltip="click row for details">{{ r.stationId }}</td>
  </tr>
  </tbody>
</table>
