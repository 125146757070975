import {Component, Input} from "@angular/core";
import {Facet} from "../../../record/feature-print-models";

@Component({
  selector: 'heraeus-facet',
  templateUrl: './heraeus-facet.component.html',
  styleUrls: ['./heraeus-facet.component.scss']
})
export class HeraeusFacetComponent {
  @Input() public facet: Facet | undefined;
  @Input() public previewImageUrl: string | null = null;
}
