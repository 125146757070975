import {StatsByDataset} from "../../../../components/featureprint-stats/featureprint-stats.component";
import {Component, Input} from "@angular/core";

@Component({
  selector: 'richemont-fp-stats',
  templateUrl: './richemont-stats-view.component.html',
  styleUrls: ['./richemont-stats-view.component.scss']
})
export class RichemontStatsViewComponent {

  @Input() totals: StatsByDataset[] = [];

}

