import {StatsByDataset} from "../../../../components/featureprint-stats/featureprint-stats.component";
import {Component, Input} from "@angular/core";

@Component({
  selector: 'heraeus-fp-stats',
  templateUrl: './heraeus-stats-view.component.html',
  styleUrls: ['./heraeus-stats-view.component.scss']
})
export class HeraeusStatsViewComponent {

  @Input() totals: StatsByDataset[] = [];

}

