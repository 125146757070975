import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeraeusStockItemComponent} from "./heraeus-stock-item/heraeus-stock-item.component";
import {RecordModule} from "../../record/record.module";
import {UtilityModule} from "../../utility/utility.module";
import {AngularMaterialModule} from "../../angular-material.module/angular-material.module.module";
import {
  HeraeusFeatureprintListViewComponent
} from "./heraeus-featureprint-list-view/heraeus-featureprint-list-view.component";
import {RouterLink} from "@angular/router";
import {
  HeraeusIdentificationsListViewComponent
} from "./heraeus-identifications-list-view/heraeus-identifications-list-view.component";
import {HeraeusStatsViewComponent} from "./heraeus-stats-view/heraeus-stats-view.component";
import {HeraeusFacetComponent} from "./facet/heraeus-facet.component";

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    RecordModule,
    UtilityModule,
    RouterLink
  ],
  declarations: [
    HeraeusStockItemComponent
    , HeraeusFeatureprintListViewComponent
    , HeraeusIdentificationsListViewComponent
    , HeraeusStatsViewComponent
    , HeraeusFacetComponent
  ],
  exports: [
    HeraeusStockItemComponent,
    HeraeusFeatureprintListViewComponent,
    HeraeusIdentificationsListViewComponent,
    HeraeusStatsViewComponent,
    HeraeusFacetComponent
  ],
  providers: []
})
export class HeraeusModule {
}

export interface IdWithIndex {
  id: string;
  index: number;
}
