import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StockItem, StockItemSearchResult} from "../../../record/feature-print-models";
import {IdWithIndex} from "../heraeus.module";

@Component({
  selector: 'heraeus-fp-list',
  templateUrl: './heraeus-featureprint-list-view.component.html',
  styleUrls: ['./heraeus-featureprint-list-view.component.scss']
})
export class HeraeusFeatureprintListViewComponent {

  @Input() stockItems: StockItemSearchResult[] = [];
  @Input() currentStockItem: string | null = null;
  @Input() dataset: string | null = null;

  @Output() setStockItemEvent: EventEmitter<IdWithIndex> = new EventEmitter<IdWithIndex>();

  getSerialNumber(externalIdentifier: string): string {
    try {
      const json = JSON.parse(externalIdentifier);
      return json.serialNumber;
    } catch (e:any) {
      return externalIdentifier;
    }
  }

}

