<table class="table table-sm table-hover table-striped">
  <thead>
  <tr>
    <th>Label</th>
    <th>Maison</th>
    <th>Enabled Facets</th>
    <th>Created</th>
    <th>Last Facet Created</th>
    <th>Station</th>
    <th>User</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let r of stockItems; index as i" #stockItemList
      [ngClass]="currentStockItem === r.stockItemId ? 'ae-select link-active' : 'link-active'"
      (click)="setStockItemEvent.emit({id: r.stockItemId, index: i})"
  >
    <td>
      <a class="link-active" matTooltip="click to open FeaturePrint in new tab"
         target="_blank"
         [routerLink]="['/featurePrint', this.dataset, r.stockItemId]"> {{ r.label | ellipsis:30 }} </a>
    </td>
    <td matTooltip="click row for details">{{ r.maisonCode }}</td>
    <td matTooltip="click row for details">{{ r.facetCount }}</td>
    <td matTooltip="click row for details">{{ r.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
    <td matTooltip="click row for details">{{ r.maxFacetCreated | date: "yyyy-MM-dd HH:mm:ss" }}</td>
    <td matTooltip="click row for details">{{ r.stationId }}</td>
    <td matTooltip="click row for details">{{ r.author }}</td>
  </tr>
  </tbody>
</table>
