<table class="table table-sm table-hover table-striped">
  <thead>
  <tr>
    <th>Identification ID</th>
    <th>Expected Item</th>
    <th class="td-center">Identification Outcome</th>
    <th>Identified Item</th>
    <th>Station</th>
    <th>User ID</th>
    <th>Created</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let ir of identificationResults; index as i" #stockItemList
      [ngClass]="currentIdentificationId === ir.identificationId ? 'ae-select link-active' : 'link-active'"
      (click)="setIdentificationEvent.emit({id: ir.identificationId, index: i})"
  >
    <td>
      <a class="link-active" matTooltip="click to open FeaturePrint in new tab"
         target="_blank"
         [routerLink]="['/identification', this.dataset, ir.identificationId]"> {{ ir.identificationId }} </a>
    </td>
    <td matTooltip="click row for details">{{ ir.identificationLabel | ellipsis:30 }}</td>
    <td class="td-center">
      <mat-icon *ngIf="ir.bestMatchStockItemId && ir.identificationLabel === ir.stockItemLabel"
                color="primary" matTooltip="FeaturePrint matched the expected item.">
        check
      </mat-icon>
      <mat-icon *ngIf="ir.bestMatchStockItemId && ir.identificationLabel != ir.stockItemLabel"
                class="mismatch" matTooltip="Requires review. FeaturePrint identified a different item than expected.">
        warning
      </mat-icon>
      <mat-icon *ngIf="!ir.bestMatchStockItemId" color="warn"
                matTooltip="FeaturePrint did not identify any item. The expected item may not have been FeaturePrinted.">
        close
      </mat-icon>
    </td>
    <td matTooltip="click row for details">{{ ir.stockItemLabel | ellipsis:30 }}</td>
    <td matTooltip="click row for details">{{ ir.stationId }}</td>
    <td matTooltip="click row for details">{{ ir.author }}</td>
    <td matTooltip="click row for details">{{ ir.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
  </tr>
  </tbody>
</table>
