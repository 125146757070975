import {Component, Input} from '@angular/core';

export interface JsonLabel {
  label: string;
  text: string;
}

@Component({
  selector: 'ae-json',
  templateUrl: './json-view.component.html',
  styleUrls: ['./json-view.component.scss'],
})
export class JsonViewComponent {
  jsonInput: any = {};
  jsonOutput: JsonLabel[] = [];

  @Input() set json(json: any) {
    if (json) {
      this.jsonInput = json;
      this.processJson();
    }
  };

  processJson() {
    //console.log("json", this.jsonInput);
    let output: JsonLabel[] = [];
    for (let key in this.jsonInput) {
      const val = this.jsonInput[key];
      const item = this.processValue(key, val);
      output = output.concat(item);
    }
    // strip out duplicate object-break entries
    for (let i = output.length - 1; i > -1; i--) {
      if(output[i].label == 'object-break') {
        if (i > 0 && output[i-1].label == 'object-break') {
          //remove from the list
          output.splice(i, 1);
        }
      }
    }
    if(output.length > 0 && output[output.length - 1].label == 'object-break') {
      output.pop();
    }
    this.jsonOutput = output;
  }

  processValue(key: any, val: any): any[] {
    let tp = typeof val;

    let parsedValue = val;
    //console.log("json parse", tp, key, val, parsedValue);
    if (tp === 'string') {
      parsedValue = this.tryParseJson(val);
      tp = typeof parsedValue;
    }

    if (tp === 'object') {
      let output: any[] = [];
      //console.log("json obj", key, val, parsedValue);
      for (let subKey in parsedValue) {
        const subVal = parsedValue[subKey];
        output = output.concat(this.processValue(`${key}.${subKey}`, subVal));
      }
      output.push({label: 'object-break', text: ''})
      return output;
    } else {
      return [{label: key, text: val}];
    }
  }

  tryParseJson(jsonValue: any): any {
    try {
      return JSON.parse(jsonValue);
    } catch (e: any) {
      console.log("error", e, jsonValue);
      return jsonValue;
    }
  }
}
