import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IdWithIndex} from "../richemont.module";
import {IdentificationSearchResult} from "../../../record/identification-models";

@Component({
  selector: 'richemont-identifications-list',
  templateUrl: './richemont-identifications-view.component.html',
  styleUrls: ['./richemont-identifications-view.component.scss']
})
export class RichemontIdentificationsViewComponent {

  @Input() identificationResults: IdentificationSearchResult[] = [];
  @Input() currentIdentificationId: string | null = null;
  @Input() dataset: string | null = null;

  @Output() setIdentificationEvent: EventEmitter<IdWithIndex> = new EventEmitter<IdWithIndex>();

}

