<ae-nav></ae-nav>

<div>
  <div class="search-filter">
    <ae-search-filter class="search-filter"
                      [searchTitle]="'FeaturePrints Search'"
                      [includeDataSetSearch]="true"
                      [includeDateSearch]="true"
                      [dataSetList]="datasetNames"
                      [statusList]="lookups.statusList"
                      (searchEvent)="searchHandler($event)"
                      (resetSearchEvent)="resetSearchHandler()"
    >
    </ae-search-filter>
  </div>

  <div #stockItemTop class="search-filter">
    <mat-card *ngIf="stockItems && stockItems.length > 0">
      <div class="header-menu">
        <h3 class="header-menu-title">Item Count: {{ searchResultsTotal || 0 }}</h3>
        <button *ngIf="currentStockItem" mat-raised-button (click)="clearCurrent();">
          Clear Selection
        </button>
      </div>

      <div class="scroll-pane scroll-pane-all"
           [ngClass]="{'scroll-pane-detail': currentStockItem}">

        <heraeus-fp-list *ngIf="customer == 'heraeus'"
                         [currentStockItem] = currentStockItem
                         [stockItems]="stockItems"
                         [dataset]="searchDataset"
                         (setStockItemEvent)="stockItemChangeHandler($event)"
        ></heraeus-fp-list>
        <richemont-fp-list *ngIf="customer == 'richemont'"
                           [currentStockItem] = currentStockItem
                           [stockItems]="stockItems"
                           [dataset]="searchDataset"
                           (setStockItemEvent)="stockItemChangeHandler($event)"
        ></richemont-fp-list>
      </div>
    </mat-card>
    <mat-paginator #paginator
                   [length]="searchResultsTotal || 0"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [hidden]="searchResultsTotal < 1"
                   (page)="pageHandler($event)"
    ></mat-paginator>
    <mat-card *ngIf="stockItems &&  stockItems.length === 0" class="no-match">
      <mat-card-content>
        No Matching Records
      </mat-card-content>
    </mat-card>

    <ae-featureprint *ngIf="currentStockItem"
                   [stockItemSelector]="selectedStockItem"
                   [fullView]="false"
                   (stockItemChange)="registrationChangeHandler($event)">
    </ae-featureprint>
  </div>
</div>
