import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StockItemSearchResult} from "../../../record/feature-print-models";
import {IdWithIndex} from "../richemont.module";

@Component({
  selector: 'richemont-fp-list',
  templateUrl: './richemont-featureprint-list-view.component.html',
  styleUrls: ['./richemont-featureprint-list-view.component.scss']
})
export class RichemontFeatureprintListViewComponent {

  @Input() stockItems: StockItemSearchResult[] = [];
  @Input() currentStockItem: string | null = null;
  @Input() dataset: string | null = null;

  @Output() setStockItemEvent: EventEmitter<IdWithIndex> = new EventEmitter<IdWithIndex>();

}

