import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RichemontStockItemComponent} from "./stock-item/richemont-stock-item.component";
import {UtilityModule} from "../../utility/utility.module";
import {RecordModule} from "../../record/record.module";
import {AngularMaterialModule} from "../../angular-material.module/angular-material.module.module";
import {
  RichemontFeatureprintListViewComponent
} from "./richemont-featureprint-list-view/richemont-featureprint-list-view.component";
import {RouterLink} from "@angular/router";
import {
  RichemontIdentificationsViewComponent
} from "./richemont-identifications-list-view/richemont-identifications-view.component";
import {RichemontStatsViewComponent} from "./richemont-stats-view/richemont-stats-view.component";
import {RichemontFacetComponent} from "./facet/richemont-facet.component";

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    RecordModule,
    UtilityModule,
    RouterLink

  ],
  declarations: [
    RichemontStockItemComponent,
    RichemontFeatureprintListViewComponent,
    RichemontIdentificationsViewComponent,
    RichemontStatsViewComponent,
    RichemontFacetComponent
  ],
  exports: [
    RichemontStockItemComponent,
    RichemontFeatureprintListViewComponent,
    RichemontIdentificationsViewComponent,
    RichemontStatsViewComponent,
    RichemontFacetComponent
  ],
  providers: []
})
export class RichemontModule {
}

export interface IdWithIndex {
  id: string;
  index: number;
}
