import {Component, Input} from '@angular/core';
import {StockItem} from "../../../record/feature-print-models";

@Component({
  selector: 'heraeus-stock-item',
  templateUrl: './heraeus-stock-item.component.html',
  styleUrls: ['./heraeus-stock-item.component.scss']
})
export class HeraeusStockItemComponent {

  @Input() stockItem: StockItem | null = null;

  getSerialNumber(): string {
    try {
      const jason = JSON.parse(this.stockItem?.externalIdentifier as string);
      return jason["serialNumber"] as string;
    } catch (e: any) {
      return "";
    }
  }
}
