import {Component, Input, OnInit} from '@angular/core';
import {PortalApiService} from '../../modules/record/portal-api.service';
import {Facet} from "../../modules/record/feature-print-models";
import {BaseService} from "../../modules/base/base.service";

export interface FacetWithDataset{
  facet: Facet | undefined;
  dataset: string | undefined;
}

@Component({
  selector: 'ae-facet',
  templateUrl: './facet.component.html',
  styleUrls: ['./facet.component.scss']
})
export class FacetComponent implements OnInit {
  public facet: Facet | undefined;
  public previewImageUrl: string | null = null;
  protected dataset: string | undefined;
  customer = "";

  @Input() set facetInput(value: FacetWithDataset | undefined) {
    this.facet = value?.facet;
    this.dataset = value?.dataset;
    this.getPreview();
  }

  constructor(private recordApi: PortalApiService, private baseSvc: BaseService) {
  }

  ngOnInit(): void {
    this.customer = this.baseSvc.appConfig().customerName;
  }

  getPreview(): void {
    if (this.facet && this.dataset) {
      this.previewImageUrl = this.recordApi.computePreviewUrl(this.facet.previewImageKey, this.dataset);
    }
  }
}
