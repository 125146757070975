<ae-nav></ae-nav>
<div>

  <div>
    <div class="search-filter">
      <ae-search-filter class="search-filter"
                        [searchTitle]="'Identifications Search'"
                        [includeDateSearch]="true"
                        [dataSetList]="datasetNames"
                        (searchEvent)="searchHandler($event)"
                        (resetSearchEvent)="resetHandler()"
      >
      </ae-search-filter>
    </div>

    <div #authTop class="search-filter">
      <mat-card *ngIf="searchResult && searchResult.length > 0">
        <div class="header-menu">
          <h3 class="header-menu-title">Identifications Count: {{ searchResultCount || 0 }}</h3>
          <button *ngIf="currentIdentificationId" mat-raised-button (click)="clearCapture();">
            Clear Selection
          </button>
        </div>

        <div class="scroll-pane scroll-pane-all"
             [ngClass]="{'scroll-pane-detail': currentIdentificationId}">
          <heraeus-identification-list *ngIf="customer == 'heraeus'"
            [identificationResults]="searchResult"
            [currentIdentificationId]="currentIdentificationId"
            [dataset]="searchDataset"
            (setIdentificationEvent)="identificationChangeHandler($event)"
          ></heraeus-identification-list>

          <richemont-identifications-list *ngIf="customer == 'richemont'"
            [identificationResults]="searchResult"
            [currentIdentificationId]="currentIdentificationId"
            [dataset]="searchDataset"
            (setIdentificationEvent)="identificationChangeHandler($event)"
          ></richemont-identifications-list>

        </div>
      </mat-card>
      <mat-paginator #paginator
                     [length]="searchResultCount || 0"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     [showFirstLastButtons]="true"
                     [hidden]="searchResultCount < 1"
                     (page)="pageHandler($event)"
      ></mat-paginator>

      <mat-card *ngIf="searchResult &&  searchResult.length === 0" class="no-match">
        <mat-card-content>
          No Matching Records
        </mat-card-content>
      </mat-card>

      <ae-identification *ngIf="currentIdentificationId && currentIdentification"
                         [fullView]="false"
                         [identificationRecord]="currentIdentificationWithDataset"
      ></ae-identification>

    </div>
  </div>
</div>
