import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SearchCriteria} from '../../modules/record/portal-api.service';
import {StatusSearchItem,} from '../../modules/core.service';
import {MatSelectChange} from '@angular/material/select';
import {dateRangeValidator} from './search-filter-component.directive';

export class QuickSearchRangeItem {
  rangeInMinutes: number;
  display: string;

  constructor(rangeInMinutes: number, display: string) {
    this.rangeInMinutes = rangeInMinutes;
    this.display = display;
  }
}

@Component({
  selector: 'ae-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  currentDataset: string | null = null;

  @Output() searchEvent: EventEmitter<SearchCriteria> =
    new EventEmitter<SearchCriteria>();
  @Output() resetSearchEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() searchTitle!: string;
  @Input() includeDateSearch = false;
  @Input() includeDataSetSearch = true;
  @Input() includeStatusSearch = false;
  @Input() stationList: string[] = [];
  @Input() dataSetList?: string[] = [];
  @Input() statusList: StatusSearchItem[] = [{val: null, display: 'all'}];
  @Input() quickSearchList: QuickSearchRangeItem[] = [
    {rangeInMinutes: 5, display: 'Last 5 Minutes'},
    {rangeInMinutes: 10, display: 'Last 10 Minutes'},
    {rangeInMinutes: 15, display: 'Last 15 Minutes'},
    {rangeInMinutes: 20, display: 'Last 20 Minutes'},
    {rangeInMinutes: 30, display: 'Last 30 Minutes'},
    {rangeInMinutes: 60, display: 'Last Hour'},
    {rangeInMinutes: 120, display: 'Last 2 Hours'},
    {rangeInMinutes: 240, display: 'Last 4 Hours'},
    {rangeInMinutes: 360, display: 'Last 6 Hours'},
    {rangeInMinutes: 1440, display: 'Last Day'},
    {rangeInMinutes: 10080, display: 'Last Week'},
  ];
  @Input() isExpanded = true;

  @Input() set setStartDate(val: Date) {
    this.startDate.setValue(val);
  }

  @Input() set setEndDate(val: Date) {
    this.endDate.setValue(val);
  }

  @Input() set setDataSets(val: string[]) {
    this.dataSets.setValue(val);
  }

  @Input() set setStatus(val: string) {
    this.statusCtl.setValue(val);
  }

  @Input() set setSearchText(val: string) {
    this.searchText.setValue(val);
  }

  page = 0;
  searchForm: FormGroup;
  searchQuickRange = new FormControl();
  startDate = new FormControl();
  endDate = new FormControl();
  dataSets = new FormControl();
  statusCtl = new FormControl();
  searchText = new FormControl();
  dateForm = new FormGroup({
    startDate: this.startDate,
    endDate: this.endDate,
  }, [dateRangeValidator]);

  constructor(formBuilder: FormBuilder) {
    this.searchForm = formBuilder.group({
      quickSearch: this.searchQuickRange,
      dateForm: this.dateForm,
      dataSets: this.dataSets,
      status: this.statusCtl,
      searchText: this.searchText,
    });
  }

  ngOnInit(): void {
    this.dataSets.valueChanges.subscribe((value: string) => {
      const newVal = value;
      this.currentDataset = newVal;
    });

    // init search range with first value
    this.searchQuickRange.setValue(null);
  }

  onSubmit(): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.isExpanded = false;
    const formValue = this.searchForm.value;
    const dateValue = this.dateForm.value;
    const dataset = formValue.dataSets;
    const searchText = formValue.searchText;
    const status = formValue.status ? formValue.status : null;
    this.setDateControls();
    const searchCrit = new SearchCriteria(
      dataset,
      dateValue.startDate,
      dateValue.endDate,
      searchText
    );
    this.searchEvent.emit(searchCrit);
  }

  canSearch(): boolean {
    return this.dataSets.value != null &&
      (!this.includeDateSearch || (this.includeDateSearch && this.startDate.value != null && this.endDate.value != null))
  }

  cancelSearch(): void {
    this.isExpanded = false;
  }

  resetSearch(): void {
    this.page = 0;
    this.searchForm.reset();
    this.resetSearchEvent.emit();
  }

  onChangeStartDate(event: any): void {
    this.searchQuickRange.setValue(null);
  }

  onChangeEndDate(event: any): void {
    this.searchQuickRange.setValue(null);
  }

  onChangeQuickSearch(event: MatSelectChange): void {
    if (!event.value) {
      this.startDate.setValue(null);
      this.endDate.setValue(null);
      return;
    }
    this.setDateControls();
  }

  formatDataset(dataset: string): string {
    if (!dataset) {
      return "";
    } else {
      return `${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")}`
    }
  }

  private setDateControls(): void {
    if (this.searchQuickRange.value) {
      const endMillis = Date.now();
      const startMillis = endMillis - this.searchQuickRange.value * 60 * 1000;
      this.endDate.setValue(new Date(endMillis));
      this.startDate.setValue(new Date(startMillis));
    }
  }
}
