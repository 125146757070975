import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BaseService} from "./base.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RequestInterceptor} from "./http-interceptor";
import {ConfigService} from "./config.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    BaseService,
    ConfigService,
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true}
  ],
  exports: [],
  bootstrap: []
})
export class BaseModule {
}

export interface IdWithIndex {
  id: string;
  index: number;
}
